import { extendTheme, ThemeOverride } from '@chakra-ui/react'
const theme = {
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false
    },
    fonts: {
        body: 'montserrat',
        heading: 'montserrat'
    }
} as ThemeOverride

export default extendTheme(theme)